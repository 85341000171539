import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  Select,
  MenuItem,
  Skeleton,
  TextField,
  Radio,
  RadioGroup,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import { cnpj } from "cpf-cnpj-validator";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatCnpj } from "./utils/format";

function Home() {
  let [searchParams] = useSearchParams();
  const [typeSelected, setTypeSelected] = React.useState("CREDIT_CARD");
  const navigate = useNavigate();
  const [plan, setPlan] = React.useState(null);
  const [havePlan, setHavePlan] = React.useState(null);
  const [installments, setInstallments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const handlePresetSelection = (e) => {
    setValue(
      "store_pre_selection",
      e.target.value === "" ? null : e.target.value
    );
  };

  const handleStoreHasPreset = (e) => {
    setValue("has_store_preset", e.target.value === "" ? null : e.target.value);
  };

  const cnpjFromQuery = searchParams.get("cnpj");
  const zipFromQuery = searchParams.get("zip");

  let params = useParams();
  let base_url = process.env.REACT_APP_API_URL;

  const isType = (value) => {
    value = value.toString();

    value = value.replace(/[^0-9]/g, "");

    if (value.length === 14) {
      return "CNPJ";
    } else {
      return false;
    }
  };

  const checkCPFCNPJ = (value) => {
    if (!value) {
      return "Documento inválido";
    }

    const type = isType(value);

    if (type === "CNPJ") {
      const valid = cnpj.isValid(value);
      return valid ? undefined : "CNPJ inválido";
    } else if (!type && !cnpjFromQuery) {
      return "Documento inválido";
    }

    return cnpjFromQuery ? undefined : "Documento inválido";
  };

  const handleCep = async (event, isValue) => {
    const value = isValue ? event : event.target.value;
    let data = value.replace(/\D/g, "");
    let cpf = "";
    let parts = Math.ceil(data.length / 5);
    for (let i = 0; i < parts; i++) {
      if (i === 5) {
        cpf += `${data.substr(i * 5)}`;
        break;
      }
      cpf += `${i !== 0 ? "-" : ""}${data.substr(i * 5, 5)}`;
    }
    data = cpf;
    if (data.length === 9) {
      const cep = await axios.get(`https://viacep.com.br/ws/${data}/json/`);
      setValue("street_name", cep.data.logradouro);
      setValue("district", cep.data.bairro);
      setValue("city", cep.data.localidade);
      setValue("state", cep.data.uf);
    }
    setValue("zip", data);
  };

  const handleCpfCnpjChange = async (event) => {
    let data = formatCnpj(event.target.value);

    if (checkCPFCNPJ(data) === undefined) {
      let data_formatted = data.replace(/\D/g, "");
      await axios
        .get(`${base_url}/all/merchants/${data_formatted}/subscription`)
        .then((response) => {
          if (response.data?.exist) {
            setError("document", {
              message: "CNPJ já está sendo usado por outro lojista",
            });
          } else {
            clearErrors();
          }
          setHavePlan(response.data);
        })
        .catch((error) => {
          setHavePlan(null);
          log("ERROR_REQUEST_HAVE_PLAN", error.response.data?.error);
        });
    } else {
      setHavePlan(null);
    }

    setValue("document", data);
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    control,
    watch,
  } = useForm({});

  useEffect(() => {
    if (cnpjFromQuery && zipFromQuery) {
      setValue("has_store_preset", null);
      setValue("store_pre_selection", null);
      setValue("document", formatCnpj(cnpjFromQuery));
      setValue("zip", zipFromQuery);
      handleCep(zipFromQuery, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    if (havePlan?.exist) return;

    setLoading(true);

    try {
      let address = "";
      if (data.number) {
        address += data.number + ", ";
      }
      if (data.street_name) {
        address += data.street_name;
      }
      if (data.district) {
        address += ", " + data.district;
      }
      const zip_code = data.zip.replace(/\D+/g, "");

      let billing_address = {
        address: address,
        complement: data.complement,
        zip: zip_code,
        number: data.number,
        city: data.city,
        state: data.state,
        country: "BR",
      };

      let json = {};

      const storePreSelection = data.has_store_preset === "PRESET" ? data.store_pre_selection : null

      if (typeSelected === "CREDIT_CARD") {
        const exp = data.expiration_date.split("/");
        json = {
          plan_id: plan.id,
          name: data.name,
          taxpayer_id_number: data.document.replace(/\D/g, ""),
          email: data.email,
          password: data.password,
          phone: data.phone,
          method_payment: "CREDIT_CARD",
          type_clone: storePreSelection,
          card: {
            installments: installments ? parseInt(data.installments) : null,
            holder_name: data.card_name,
            number: data.card_number.replace(/\s/g, ""),
            expiry_month: exp[0],
            expiry_year: exp[1],
            cvv: data.cvv,
            holder: {
              name: data.name,
              email: data.email,
              phone: data.phone,
              taxpayer_id_number: data.document.replace(/\D/g, ""),
              billing_address: billing_address,
            },
          },
        };
      } else {
        json = {
          plan_id: plan.id,
          name: data.name,
          taxpayer_id_number: data.document.replace(/\D/g, ""),
          email: data.email,
          phone: data.phone,
          method_payment: typeSelected,
          password: data.password,
          address: billing_address,
          type_clone: storePreSelection,
        };
      }

      try {
        const response = await axios.post(
          `${base_url}/all/merchants/register`,
          json
        );

        if (response.data.message) {
          if (response.data.message === "Assinatura criada com sucesso!") {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
          let payload = {
            request: json,
            message: response.data.message,
          };
          log("SUCCESS_REQUEST_MERCHANT_PLAN", payload);
          if (typeSelected === "CREDIT_CARD") {
            navigate("/sucess/redirect");
          } else {
            navigate("/payment", {
              state: {
                payment: response.data,
                document: data.document.replace(/\D/g, ""),
              },
            });
          }
        }
      } catch (error) {
        if (error.response.data.error.length > 0) {
          error.response.data.error.forEach((element) => {
            toast.error(element, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          });

          let payload = {
            request: json,
            message: error.response.data.error,
          };
          log("ERROR_REQUEST_MERCHANT_PLAN", payload);
        }
      }
    } catch (error) {
      let payload = {
        request: data,
        message: error,
      };
      log("ERROR_MERCHANT_PLAN", payload);
    }

    setLoading(false);
  };

  const log = async (channel, payload) => {
    await axios.post(`${base_url}/logs`, {
      url: window.location.href,
      channel: channel,
      payload: JSON.stringify(payload),
    });
  };

  const handleCardNumber = (event) => {
    let data = event.target.value.replace(/\D/g, "");

    let cpf = "";
    let parts = Math.ceil(data.length / 4);
    for (let i = 0; i < parts; i++) {
      if (i === 7) {
        break;
      }
      cpf += `${i !== 0 ? " " : ""}${data.substr(i * 4, 4)}`;
    }
    data = cpf;
    setValue("card_number", data);
  };

  const handleValidDate = (event) => {
    let data = event.target.value.replace(/\D/g, "");

    let cpf = "";
    let parts = Math.ceil(data.length / 2);
    for (let i = 0; i < parts; i++) {
      if (i === 2) {
        cpf += `${data.substr(i * 2)}`;
        break;
      }
      cpf += `${i !== 0 ? "/" : ""}${data.substr(i * 2, 2)}`;
    }
    data = cpf;
    setValue("expiration_date", data);
  };

  useEffect(() => {
    axios
      .get(`${base_url}/all/plans/${params.slug}`)
      .then((response) => {
        setPlan(response.data);
        let array = [];
        for (let i = 1; i <= response.data.options?.installments?.length; i++) {
          array.push(
            (response.data.price_cents / 100 / i).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })
          );
        }
        setInstallments(array);
        if (array?.length > 0) {
          setValue('installments', '1')
        }
      })
      .catch((error) => {
        axios.post(`${base_url}/logs`, {
          url: window.location.href,
          channel: "ERROR_REQUEST_PLAN",
          payload: JSON.stringify(error.response.data.error),
        });
      });
  }, [params.slug, base_url]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ToastContainer />
      <div className="grid grid-cols-12 bg-white place-content-center border-solid border border-slate-300 rounded mt-8 mb-8 lg:mx-[200px] p-4 mx-2">
        <div className="col-span-12 text-center">
          <center>
            <img
              src="https://2tspace.sfo3.digitaloceanspaces.com/logos_2t/onbeef.png"
              width={80}
              alt={"logo"}
            />
          </center>
        </div>
        <div className="col-span-12 prose mt-2 lg:text-left text-left">
          {plan ? (
            <>
              {plan.interval_type === "MONTH" ? (
                <>
                  <article className="sm:text-sm lg:text-xl mt-2 ">
                    {plan.name}
                  </article>
                  <article className="sm:text-xs lg:text-sm mt-2 ">
                    {(plan.price_cents / 100 / plan.interval).toLocaleString(
                      "pt-BR",
                      {
                        style: "currency",
                        currency: "BRL",
                      }
                    )}{" "}
                    por mês
                    {/* - período de {plan.interval} meses */}
                  </article>
                  <article className="mt-2">
                    <b>Preencha os dados abaixo para começar sua jornada!</b>
                  </article>
                </>
              ) : (
                <>
                  <article className="sm:text-sm lg:text-xl mt-2">
                    {plan.name}
                  </article>
                  <article className="sm:text-xs lg:text-sm mt-2">
                    {(plan.price_cents / 100).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}{" "}
                    por ano
                    {/* - período de {plan.interval} meses */}
                  </article>
                  <article className="mt-2">
                    <b>Preencha os dados abaixo para começar sua jornada!</b>
                  </article>
                </>
              )}
            </>
          ) : (
            <>
              <Skeleton />
              <Skeleton />
            </>
          )}
        </div>
        <div className="col-span-12 mt-3 mb-3">
          <hr></hr>
        </div>
        <div className="lg:col-span-9 col-span-12 prose m-2 text-left">
          <article className="lg:text-xl text-xl mt-2">
            Informações pessoais
          </article>
        </div>
        <div className="lg:col-span-4 col-span-12 m-2 ">
          <Controller
            control={control}
            name="document"
            render={({ field }) => (
              <TextField
                {...register("document", {
                  required: "CNPJ obrigatório",
                  validate: (value) => {
                    const validate = checkCPFCNPJ(value);
                    return validate;
                  },
                })}
                {...field}
                label="CNPJ"
                variant="outlined"
                fullWidth
                value={watch("document")}
                onChange={handleCpfCnpjChange}
                error={errors.document}
                helperText={errors.document?.message}
                disabled={cnpjFromQuery}
              />
            )}
          ></Controller>
        </div>
        {havePlan && havePlan?.exist === false ? (
          <>
            <div className="lg:col-span-4 col-span-12 m-2">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...register("name", {
                      required: "Razão Social é obrigatório",
                    })}
                    {...field}
                    label="Razão Social"
                    variant="outlined"
                    fullWidth
                    error={errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              ></Controller>
            </div>

            <div className="lg:col-span-4 col-span-12 m-2">
              <Controller
                control={control}
                name="phone"
                render={({ field }) => (
                  <ReactInputMask
                    {...register("phone", {
                      required: "Celular obrigatório",
                    })}
                    {...field}
                    defaultValue={null}
                    mask="(99) 99999-9999"
                  >
                    {() => (
                      <TextField
                        inputProps={{ inputMode: "numeric" }}
                        fullWidth
                        variant="outlined"
                        label="Celular"
                        error={errors.phone}
                        helperText={errors.phone?.message}
                      />
                    )}
                  </ReactInputMask>
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-6 col-span-12 m-2">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField
                    {...register("email", {
                      required: "E-mail obrigatório",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "E-mail inválido",
                      },
                      validate: (value) => {
                        if (value === getValues("email_confirm")) {
                          return undefined;
                        } else {
                          return "Verificação de e-mail incorreta";
                        }
                      },
                    })}
                    {...field}
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    error={errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-6 col-span-12 m-2">
              <Controller
                control={control}
                name="email_confirm"
                render={({ field }) => (
                  <TextField
                    {...register("email_confirm", {
                      required: "Confirmar e-mail obrigatório",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "E-mail inválido",
                      },
                      validate: (value) => {
                        if (value === getValues("email")) {
                          return undefined;
                        } else {
                          return "Verificação de e-mail incorreta";
                        }
                      },
                    })}
                    {...field}
                    label="Confirme seu e-mail"
                    variant="outlined"
                    fullWidth
                    error={errors.email_confirm}
                    helperText={errors.email_confirm?.message}
                  />
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-6 col-span-12 m-2">
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <TextField
                    {...register("password", {
                      required: "Senha obrigatória",
                      validate: (value) => {
                        if (value === getValues("password_confirm")) {
                          return undefined;
                        } else {
                          return "Verificação de senha incorreta";
                        }
                      },
                    })}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                    label="Crie uma senha de acesso"
                    variant="outlined"
                    fullWidth
                    error={errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-6 col-span-12 m-2">
              <Controller
                control={control}
                name="password_confirm"
                render={({ field }) => (
                  <TextField
                    {...register("password_confirm", {
                      required: "Confirmação de senha obrigatória",
                      validate: (value) => {
                        if (value === getValues("password")) {
                          return undefined;
                        } else {
                          return "Verificação de senha incorreta";
                        }
                      },
                    })}
                    type={showConfirmPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmShowPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    {...field}
                    label="Confirme sua senha"
                    variant="outlined"
                    fullWidth
                    error={errors.password_confirm}
                    helperText={errors.password_confirm?.message}
                  />
                )}
              ></Controller>
            </div>
          </>
        ) : null}
        <div className="lg:col-span-9 col-span-12 prose m-2 text-left">
          <article className="lg:text-xl text-xl mt-2">
            Endereço de cobrança
          </article>
        </div>

        <div className="lg:col-span-12 col-span-12 m-2">
          <Controller
            control={control}
            name="zip"
            render={({ field }) => (
              <TextField
                {...register("zip", {
                  required: "CEP obrigatório",
                })}
                {...field}
                label="CEP"
                type={"text"}
                inputProps={{
                  inputMode: "numeric",
                  maxLength: 10,
                }}
                onChange={handleCep}
                variant="outlined"
                fullWidth
                value={watch("zip")}
                error={errors.zip}
                helperText={errors.zip?.message}
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-6 col-span-12 m-2 ">
          <Controller
            control={control}
            name="street_name"
            render={({ field }) => (
              <TextField
                {...register("street_name", {
                  required: "Rua obrigatório",
                })}
                {...field}
                label="Rua"
                InputLabelProps={{
                  shrink: getValues("street_name") ? true : false,
                }}
                variant="outlined"
                fullWidth
                error={errors.street_name}
                helperText={errors.street_name?.message}
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-6 col-span-12 m-2">
          <Controller
            control={control}
            name="district"
            render={({ field }) => (
              <TextField
                {...register("district", {
                  required: "Bairro obrigatório",
                })}
                {...field}
                InputLabelProps={{
                  shrink: getValues("district") ? true : false,
                }}
                label="Bairro"
                variant="outlined"
                fullWidth
                error={errors.district}
                helperText={errors.district?.message}
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-6 col-span-12 m-2">
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <TextField
                {...register("state", {
                  required: "Estado obrigatório",
                })}
                {...field}
                InputLabelProps={{
                  shrink: getValues("state") ? true : false,
                }}
                label="Estado"
                variant="outlined"
                fullWidth
                error={errors.state}
                helperText={errors.state?.message}
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-6 col-span-12 m-2">
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <TextField
                {...register("city", {
                  required: "Cidade obrigatório",
                })}
                {...field}
                InputLabelProps={{
                  shrink: getValues("city") ? true : false,
                }}
                label="Cidade"
                variant="outlined"
                fullWidth
                error={errors.city}
                helperText={errors.city?.message}
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-6 col-span-12 m-2">
          <Controller
            control={control}
            name="number"
            render={({ field }) => (
              <TextField
                {...register("number", {
                  required: "Número é obrigatório",
                })}
                {...field}
                label="Número"
                variant="outlined"
                fullWidth
                error={errors.number}
                helperText={errors.number?.message}
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-6 col-span-12 m-2">
          <Controller
            control={control}
            name="complement"
            render={({ field }) => (
              <TextField
                {...register("complement")}
                {...field}
                label="Complemento"
                variant="outlined"
                fullWidth
              />
            )}
          ></Controller>
        </div>
        <div className="lg:col-span-9 col-span-12 prose m-2 text-left">
          <article className="lg:text-xl text-xl mt-2">Pagamento</article>
        </div>
        <div
          className={`${
            plan?.options?.installments?.length > 1
              ? "lg:col-span-4"
              : "lg:col-span-12"
          } col-span-12 m-2 border-solid border-2 border-gray-300 rounded p-2 cursor-pointer ${
            typeSelected === "CREDIT_CARD" ? "bg-sky-200" : "hover:bg-sky-100"
          }`}
          onClick={() => {
            setTypeSelected("CREDIT_CARD");
          }}
        >
          <center>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                />
              </svg>
            </div>
            <article className="sm:text-xs lg:text-base mt-2">
              Cartão de crédito
            </article>
          </center>
        </div>
        {plan?.options?.installments?.length > 1 ? (
          <>
            <div
              className={`lg:col-span-4 col-span-12 m-2 border-solid border-2 border-gray-300 rounded p-2 cursor-pointer ${
                typeSelected === "PIX" ? "bg-sky-200" : "hover:bg-sky-100"
              }`}
              onClick={() => {
                setTypeSelected("PIX");
              }}
            >
              <center>
                <div>
                  <svg
                    width="23px"
                    height="22"
                    viewBox="0 0 22 23"
                    preserveAspectRatio="xMidYMin"
                    provider=""
                    className="icon-pix icon-colorful-tab pix-color"
                    data-v-ec74aa6c=""
                    data-v-d2a71a6c=""
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.19223 5.24323C6.06969 5.24323 6.89487 5.58498 7.51525 6.20516L10.8818 9.57225C11.1243 9.8147 11.5202 9.81575 11.7633 9.57193L15.1175 6.21736C15.738 5.59718 16.5632 5.25554 17.4407 5.25554H17.8447L13.5842 0.995107C12.2574 -0.331702 10.1063 -0.331702 8.77948 0.995107L4.53135 5.24323H5.19223ZM17.4406 17.108C16.5632 17.108 15.738 16.7664 15.1176 16.1462L11.7632 12.792C11.5278 12.5558 11.1173 12.5565 10.8819 12.792L7.51531 16.1585C6.89482 16.7786 6.06964 17.1202 5.19219 17.1202H4.5312L8.77943 21.3686C10.1062 22.6953 12.2574 22.6953 13.5842 21.3686L17.8447 17.108H17.4406ZM18.794 6.20484L21.3686 8.77947C22.6954 10.1062 22.6954 12.2573 21.3686 13.5842L18.7941 16.1587C18.7373 16.1359 18.6761 16.1218 18.6112 16.1218H17.4407C16.8354 16.1218 16.243 15.8764 15.8154 15.4484L12.4611 12.0945C11.8532 11.4859 10.7925 11.4862 10.184 12.0942L6.81744 15.4607C6.38976 15.8886 5.79746 16.134 5.19222 16.134H3.75286C3.69154 16.134 3.634 16.1486 3.57983 16.169L0.995108 13.5842C-0.331703 12.2573 -0.331703 10.1062 0.995108 8.77947L3.57994 6.19464C3.63411 6.21504 3.69154 6.22956 3.75286 6.22956H5.19222C5.79746 6.22956 6.38976 6.47496 6.81744 6.90285L10.1843 10.2697C10.4982 10.5833 10.9103 10.7404 11.3227 10.7404C11.7349 10.7404 12.1473 10.5833 12.4611 10.2694L15.8154 6.91505C16.243 6.48716 16.8354 6.24176 17.4407 6.24176H18.6112C18.676 6.24176 18.7373 6.22756 18.794 6.20484Z"
                      data-v-ec74aa6c=""
                    ></path>
                  </svg>
                </div>
                <article className="sm:text-xs lg:text-base mt-2">PIX</article>
              </center>
            </div>
            <div
              className={`lg:col-span-4 col-span-12 m-2 border-solid border-2 border-gray-300 rounded p-2 cursor-pointer ${
                typeSelected === "BOLETO" ? "bg-sky-200" : "hover:bg-sky-100"
              }`}
              onClick={() => {
                setTypeSelected("BOLETO");
              }}
            >
              <center>
                <div>
                  <svg
                    fill="#000000"
                    width="23px"
                    height="22"
                    viewBox="0 0 22 23"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>barcode</title>
                    <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z"></path>
                  </svg>
                </div>
                <article className="sm:text-xs lg:text-base mt-2">
                  BOLETO
                </article>
              </center>
            </div>
          </>
        ) : null}
        {typeSelected === "CREDIT_CARD" ? (
          <>
            <div className="lg:col-span-12 col-span-12 m-2">
              <Controller
                control={control}
                name="card_number"
                render={({ field }) => (
                  <TextField
                    {...register("card_number", {
                      required: "Número do cartão obrigatório",
                    })}
                    {...field}
                    label="Número do cartão"
                    variant="outlined"
                    fullWidth
                    onChange={handleCardNumber}
                    error={errors.card_number}
                    helperText={errors.card_number?.message}
                  />
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-12 col-span-12 m-2">
              <Controller
                control={control}
                name="card_name"
                render={({ field }) => (
                  <TextField
                    {...register("card_name", {
                      required: "Nome no cartão obrigatório",
                    })}
                    {...field}
                    label="Nome no cartão"
                    variant="outlined"
                    fullWidth
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={errors.card_name}
                    helperText={errors.card_name?.message}
                  />
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-9 col-span-8 m-2 ">
              <Controller
                control={control}
                name="expiration_date"
                render={({ field }) => (
                  <TextField
                    {...register("expiration_date", {
                      required: "Data da expiração obrigatório",
                    })}
                    {...field}
                    label="Data da expiração"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      inputMode: "numeric",
                      maxLength: 5,
                    }}
                    error={errors.expiration_date}
                    onChange={handleValidDate}
                    helperText={errors.expiration_date?.message}
                  />
                )}
              ></Controller>
            </div>
            <div className="lg:col-span-3 col-span-4 m-2">
              <Controller
                control={control}
                name="cvv"
                render={({ field }) => (
                  <TextField
                    {...register("cvv", {
                      required: "CVV obrigatório",
                      validate: (value) => {
                        return value.length >= 3 ? undefined : "CVV inválido";
                      },
                    })}
                    inputProps={{ maxLength: 4, inputMode: "numeric" }}
                    {...field}
                    label="CVV"
                    variant="outlined"
                    fullWidth
                    error={errors.cvv}
                    helperText={errors.cvv?.message}
                  />
                )}
              ></Controller>
            </div>
            {plan?.options?.installments?.length > 1 && (
              <div className="lg:col-span-3 col-span-4 m-2">
                <Controller
                  control={control}
                  name="installments"
                  render={({ field }) => (
                    <FormControl fullWidth error={errors.installments}>
                      <InputLabel htmlFor="name-native-error" shrink={true}>
                        Parcelamento
                      </InputLabel>
                      <Select
                        fullWidth
                        {...register("installments", {
                          required: "Parcelamento obrigatório",
                        })}
                        {...field}
                        onChange={(e) => {
                          setValue("installments", e.target.value);
                        }}
                        native
                        label="Parcelamento"
                      >
                        {installments.map((item, index) => (
                          <option value={index + 1}>
                            {index + 1}x - {item}
                          </option>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors.installments && errors.installments?.message}
                      </FormHelperText>
                    </FormControl>
                  )}
                ></Controller>
              </div>
            )}
          </>
        ) : null}
        <div className="lg:col-span-12 col-span-12 m-2">
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              {!cnpjFromQuery && !zipFromQuery && (
                <>
                  <div className="w-full mt-2 mb-3 rounded-md ">
                    <Controller
                      control={control}
                      name="has_store_preset"
                      value={watch("has_store_preset")}
                      onClick={(e) => handleStoreHasPreset(e)}
                      error={errors.has_store_preset}
                      helpertext={errors.has_store_preset?.message}
                      render={({ field }) => {
                        return (
                          <RadioGroup>
                            <div className="w-full rounded-md ">
                              <Radio
                                {...register("has_store_preset", {
                                  required: "Selecione uma opção",
                                })}
                                {...field}
                                id="preselect1"
                                name="has_store_preset"
                                value="PRESET"
                              />
                              <label
                                htmlFor="preselect1"
                                className="text-sm text-[#7F8EA1] ml-1"
                              >
                                Quero receber minha loja pré-cadastrada.
                              </label>
                            </div>
                            {watch("has_store_preset") === "PRESET" && (
                              <div className="w-full rounded-md px-10">
                                Como você quer o pré-cadastro da sua loja?
                                <Select
                                  {...register("store_pre_selection", {
                                    required: "Selecione uma opção",
                                  })}
                                  onChange={(e) => handlePresetSelection(e)}
                                  value={watch("store_pre_selection")}
                                  error={errors.store_pre_selection}
                                  helpertext={
                                    errors.store_pre_selection?.message
                                  }
                                  defaultValue={"DAY_TO_DAY_WITHOUT_PHOTOS"}
                                  fullWidth
                                >
                                  <MenuItem value="DAY_TO_DAY_WITH_PHOTOS">
                                    Dia a dia c/ foto
                                  </MenuItem>
                                  <MenuItem value="DAY_TO_DAY_WITHOUT_PHOTOS">
                                    Dia a dia s/foto
                                  </MenuItem>
                                  <MenuItem value="BOUTIQUE_WITH_PHOTOS">
                                    Boutique c/foto
                                  </MenuItem>
                                  <MenuItem value="BOUTIQUE_WITHOUT_PHOTOS">
                                    Boutique s/foto
                                  </MenuItem>
                                </Select>
                              </div>
                            )}
                            <div className="w-full rounded-md ">
                              <Radio
                                {...register("has_store_preset", {
                                  required: "Selecione uma opção",
                                })}
                                {...field}
                                id="preselect3"
                                name="has_store_preset"
                                value="NO_PRESET"
                              />
                              <label
                                htmlFor="preselect3"
                                className="text-sm text-[#7F8EA1] ml-1"
                              >
                                Quero minha loja do zero.
                              </label>
                            </div>
                          </RadioGroup>
                        );
                      }}
                    />
                  </div>

                  <div className="w-full text-center">
                    Após esse momento, não poderá mais pedir a replicação.
                  </div>
                </>
              )}

              <div className="w-full mt-2 mb-3 rounded-md ">
                <div className="text-xs text-[#7F8EA1] ml-1">
                  Ao enviar seu pedido, você concorda com os{" "}
                  <br className="sm:hidden" />
                  <a
                    href="https://docs.google.com/document/d/1Kv2DSSvgbt1X0KILj7kklXPS7Deg9xi94_9ggp7u_fk/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline font-regular text-[#000]"
                  >
                    Termos de Serviço e Política de Uso de Dados
                  </a>{" "}
                  da Onbeef e do açougue.
                </div>
              </div>
              <Button variant="contained" type="submit" fullWidth>
                {typeSelected === "CREDIT_CARD"
                  ? "Comprar agora"
                  : typeSelected === "PIX"
                  ? "Gerar PIX"
                  : "Gerar boleto"}
              </Button>
            </>
          )}
        </div>
      </div>
    </form>
  );
}

export default Home;
