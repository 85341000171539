import React from 'react'
import 'react-toastify/dist/ReactToastify.css'

function Redirect() {
  React.useEffect(() => {
    window.location.replace('https://lojista.onbeefapp.com.br/')
  }, [])

  return (
    <>
      <div class="grid bg-white place-content-center border-solid border border-slate-300 rounded mt-8 mb-8 lg:mx-[200px] p-4 mx-2">
        <div class="lg:col-span-12 col-span-12 prose m-2 text-center">
          <article class="lg:text-[25px] text-xl mt-2">
            Aguarde alguns instantes, estamos te redirecionando para a página de
            acesso.
          </article>
        </div>
      </div>
    </>
  )
}

export default Redirect
