import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Payment from "./Payment";
import Redirect from "./Redirect";

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      <Route path="/:slug" exact element={<Home />} />
      <Route path="/payment" exact element={<Payment />} />
      <Route path="/sucess/redirect" exact element={<Redirect />} />
    </Routes>
  );
}

export default App;
