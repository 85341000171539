import { Button } from '@mui/material'
import axios from 'axios'
import copy from 'copy-to-clipboard'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function Payment() {
  const { state } = useLocation()
  const { payment, document } = state
  let base_url = process.env.REACT_APP_API_URL
  const navigate = useNavigate()

  React.useState(() => {
    console.log(payment)
  }, [])

  const copyCode = async () => {
    copy(
      payment?.payment?.transaction_type === 'PIX'
        ? payment?.payment?.qr_code
        : payment?.payment?.line
    )

    toast.success('Código copiado com sucesso!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    })
  }

  React.useEffect(() => {
    async function getStatus() {
      await axios
        .get(`${base_url}/all/merchants/${document}/subscription`)
        .then(response => {
          if (response.data?.subscription?.status === 'ACTIVE') {
            navigate('/sucess/redirect')
          }
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    }

    if (payment?.payment?.transaction_type === 'PIX') {
      // Defina o intervalo
      const interval = setInterval(() => {
        // Chame a função que você deseja executar a cada 10 segundos
        getStatus()
      }, 10000) // 10 segundos em milissegundos

      // Limpe o intervalo quando o componente desmontar
      return () => clearInterval(interval)
    }
  }, [payment?.payment?.transaction_type, base_url, document, navigate])

  // A função que você deseja executar a cada 10 segundos

  return (
    <div>
      <ToastContainer />
      <div class="grid grid-cols-12 bg-white place-content-center border-solid border border-slate-300 rounded mt-8 mb-8 lg:mx-[200px] p-4 mx-2">
        <div class="col-span-12 text-center">
          <center>
            <img
              src="https://2tspace.sfo3.digitaloceanspaces.com/logos_2t/onbeef.png"
              width={80}
              alt="logo"
            />
          </center>
        </div>
        <div class="col-span-12 mt-3 mb-3">
          <hr></hr>
        </div>
        <article class="lg:text-xl col-span-12 text-xl mt-2 mb-2 text-center">
          Realize o pagamento
        </article>
        <div class="col-span-12 text-center">
          <center>
            {payment?.payment?.transaction_type === 'PIX' ? (
              <img
                src={payment?.payment?.qr_code_url}
                width={150}
                alt={'qr_code_url'}
              />
            ) : (
              <img
                src={payment?.payment?.barcode}
                width={500}
                alt={'barcode'}
              />
            )}
          </center>
        </div>
        <article class="lg:text-base text-base col-span-12 mt-2  text-center">
          {payment?.payment?.transaction_type === 'PIX'
            ? 'Copie o código ou escaneie o QR Code'
            : 'Copie o código ou escaneie o código de barras'}
        </article>
        <div class="lg:col-span-12 col-span-12 m-2">
          <Button
            variant="contained"
            type="button"
            onClick={copyCode}
            fullWidth
          >
            Copiar código
          </Button>
        </div>
        {payment?.payment?.transaction_type === 'BOLETO' ? (
          <div class="lg:col-span-12 col-span-12 m-2">
            <a href={payment?.payment?.pdf} target="_blank" rel="noreferrer">
              <Button variant="contained" type="button" fullWidth>
                Visualizar PDF
              </Button>
            </a>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Payment
