export const formatCnpj = value => {
  let data = value.replace(/\D/g, '')
  if (data.length > 11) {
    let cnpj = `${data.substr(0, 2)}.${data.substr(2, 3)}.${data.substr(5, 3)}/`
    if (data.length > 12) cnpj += `${data.substr(8, 4)}-${data.substr(12, 2)}`
    else cnpj += data.substr(8)
    data = cnpj
  }
  return data
}
